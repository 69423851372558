import { useTheme } from "styled-components"
import Button from "../../../components/Button"
import Form from "../../../components/Form"
import GroupButtons from "../../../components/GroupButtons"
import Input from "../../../components/Input"
import Title from "../../../components/Title"
import Toggle from "../../../components/Toggle"
import { Container, GroupToggle } from "./styles"
import { ICadastroConvidadoProps } from "./types"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { Convidado, EStatus, EStatusEnvio } from "../../../models"
import { guidEmpty } from "../../../utils/guidEmpty"
import Loader from "../../../components/Loader"
import * as ConvidadoService from '../../../services/Convidado/ConvidadoService'
import { toast } from "react-toastify"
import { errorHandle } from "../../../utils/errorHandle"
import { useAuthContext } from "../../../hooks/auth"
import { removerAcentos } from "../../../utils/removerAcentos"

const CadastroConvidado: React.FC<ICadastroConvidadoProps> = () => {
    const theme = useTheme()
    const { isAdmin } = useAuthContext()
    const navigate = useNavigate()
    const { eventoId, eventoDescricao, id } = useParams()

    const isNew = id === 'new'

    const [loading, setLoading] = useState<boolean>(false)
    const [convidado, setConvidado] = useState<Convidado>({
        id: guidEmpty(),
        eventoId: eventoId!,
        nome: '',
        celular: '',
        idade: undefined,
        mesa: undefined,
        confirmado: false,
        ordem: 0,
        entrou: false,
        entrouQrCode: false,
        statusEnvio: EStatusEnvio.NaoEnviado,
        status: EStatus.Ativo
    })

    useEffect(() => {
        if(!isNew)
            getConvidado()
    // eslint-disable-next-line
    }, [isNew])

    const getConvidado = async (): Promise<void> => {
        setLoading(true)
        await ConvidadoService.buscar(id ?? '')
            .then((res) => {
                setConvidado(res.data)
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const handleNome = (nome: string): void => {
        setConvidado((prevState) => ({
            ...prevState,
            nome: removerAcentos(nome?.toUpperCase())
        }))
    }

    const handleCelular = (celular: string): void => {
        setConvidado((prevState) => ({
            ...prevState,
            celular: celular
        }))
    }

    const handleIdade = (idade: number): void => {
        setConvidado((prevState) => ({
            ...prevState,
            idade: idade
        }))
    }

    const handleMesa = (mesa: string): void => {
        setConvidado((prevState) => ({
            ...prevState,
            mesa: mesa ? mesa.toUpperCase() : undefined
        }))
    }

    const handleConfirmado = (confirmado: boolean): void => {
        setConvidado((prevState) => ({
            ...prevState,
            confirmado: confirmado
        }))
    }

    const handleEntrou = (entrou: boolean): void => {
        setConvidado((prevState) => ({
            ...prevState,
            entrou: entrou
        }))
    }

    const handleStatus = (status: EStatus): void => {
        setConvidado((prevState) => ({
            ...prevState,
            status: status
        }))
    }

    const handleSubmit = async (): Promise<void> => {
        if(isNew)
            await criarConvidado()
        else
            await atualizarConvidado()
    }

    const criarConvidado = async (): Promise<void> => {
        setLoading(true)
        await ConvidadoService.criar(convidado)
            .then(() => {
                toast.success('Convidado criado com sucesso')
                navigate(`/convidado/${eventoId}/${eventoDescricao}`)
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const atualizarConvidado = async (): Promise<void> => {
        setLoading(true)
        await ConvidadoService.atualizar(convidado)
        .then(() => {
            toast.success('Convidado atualizado com sucesso')
            navigate(`/convidado/${eventoId}/${eventoDescricao}`)
        })
        .catch((err) => toast.error(errorHandle(err)))
        .finally(() => setLoading(false))
    }

    const desabilitaCampo = (): boolean => {
        return !isNew && !isAdmin
    }

    return(
        <>
            {
                loading
                    ? <Loader />
                    : <Container>
                        <Title 
                            color={theme!.colors.warning}
                            size={30}
                        >
                            Cadastro de Convidado
                        </Title>
                        <Form>
                            <Input 
                                width={45}
                                widthMobile={93}
                                label="Nome *" 
                                placeholder="Nome *" 
                                type="text" 
                                required 
                                disabled={desabilitaCampo()}
                                value={convidado.nome}
                                onChange={(e) => handleNome(e.target.value)}
                            />
                            <Input 
                                width={45}
                                widthMobile={93}
                                label="Celular *" 
                                placeholder="Celular *" 
                                type="number"
                                min={1} 
                                required 
                                disabled={desabilitaCampo()}
                                value={convidado.celular}
                                onChange={(e) => handleCelular(e.target.value)}
                            />
                            <Input 
                                width={45}
                                widthMobile={45}
                                label="Idade" 
                                placeholder="Idade" 
                                type="number"
                                min='0'
                                disabled={desabilitaCampo()}
                                value={convidado.idade}
                                onChange={(e) => handleIdade(parseInt(e.target.value))}
                            />
                            <Input 
                                width={45}
                                widthMobile={45}
                                label="Mesa" 
                                placeholder="Mesa" 
                                type="text" 
                                required 
                                disabled={desabilitaCampo()}
                                value={convidado.mesa}
                                onChange={(e) => handleMesa(e.target.value)}
                            />
                        </Form>
                        <GroupToggle>
                            <Toggle 
                                label="Confirmado?" 
                                labelLeft="Não" 
                                labelRight="Sim" 
                                disabled={!isAdmin}
                                checked={convidado.confirmado} 
                                onChange={() => handleConfirmado(!convidado.confirmado)} 
                            />
                            <Toggle 
                                label="Entrou?" 
                                labelLeft="Não" 
                                labelRight="Sim" 
                                disabled={!isAdmin && convidado.entrouQrCode}
                                checked={convidado.entrou} 
                                onChange={() => handleEntrou(!convidado.entrou)} 
                            />
                            <Toggle 
                                label="Status" 
                                labelLeft="Inativo" 
                                labelRight="Ativo" 
                                disabled={desabilitaCampo()}
                                checked={convidado.status === EStatus.Ativo} 
                                onChange={() => handleStatus(convidado.status === EStatus.Ativo ? EStatus.Inativo : EStatus.Ativo)} 
                            />
                        </GroupToggle>
                        <GroupButtons>
                            <Button 
                                $backgroundColor={theme!.colors.info} 
                                type="button"
                                onClick={handleSubmit}
                            >
                                Salvar
                            </Button>
                            <Button 
                                $backgroundColor={theme!.colors.warning} 
                                type="button" 
                                onClick={() => navigate(`/convidado/${eventoId}/${eventoDescricao}`)}
                            >
                                Voltar
                            </Button>
                        </GroupButtons>
                    </Container>
            }
        </>
    )
}

export default CadastroConvidado